import { Provider } from "react-redux"
import store from "./src/redux/store"
import { PersistGate } from "redux-persist/integration/react"
import { persistStore } from "redux-persist"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
// import { Adapter } from "./src/components/Builder/Components/LoginAdapter/Adapter"
const React = require("react")

// Wraps every page in a component
let GOOGLE = ""
let WebSiteJava = ""
export const wrapPageElement = ({ element, props }) => {
  GOOGLE = props.pageContext.googleAnalyticsCode
  WebSiteJava = props.pageContext.websiteJavascript

  let persistor = persistStore(store)
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}

const appendThirdPartyScripts = () => {
  // appendScript(
  //   `
  // window.dataLayer = window.dataLayer || [];
  // function gtag(){dataLayer.push(arguments);}
  // gtag('js', new Date());
  // gtag('config', '${GOOGLE && GOOGLE?.split(",")[0]}');`,
  //   "textContent"
  // )
  // appendScript(
  //   `https://www.googletagmanager.com/gtag/js?id=${
  //     GOOGLE && GOOGLE?.split(",")
  //   }`,
  //   "src"
  // )

  if (WebSiteJava) {
    appendScript(WebSiteJava, "src", "defer")
  }

  // sessionStorage.setItem(loadedScripts, "true")
}
const appendScript = (scriptInnerHTML, type, load) => {
  const scriptElement = document.createElement("script")
  scriptElement[type] = scriptInnerHTML
  scriptElement[load === "defer" ? "defer" : "async"] = true
  document.body.appendChild(scriptElement)
}
const loadedScripts = "loadedScript"
const appendScriptsOnScrollOptions = { once: true }

export const onInitialClientRender = () => {
  window.addEventListener(
    "mousemove",
    appendThirdPartyScripts,
    appendScriptsOnScrollOptions
  )
}

export const onRouteUpdate = ({ prevLocation }) => {
  const checkLoadedScripts = true


  if (prevLocation && !checkLoadedScripts) {
    // Prevent appending scripts on scroll
    window.removeEventListener(
      "mousemove",
      appendThirdPartyScripts,
      appendScriptsOnScrollOptions
    )
    appendThirdPartyScripts()
  }
}
